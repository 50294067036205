import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { fetchBannerList } from "../../../../redux/bannerSlice";
import Switch from "@material-ui/core/Switch";

// Validation schema
const bannerSchema = Yup.object().shape({
  image: Yup.mixed().required("Image is required"),
  bannerName: Yup.string().required("Banner Name is required"),
  bannerURL: Yup.string().url("Invalid URL").required("Banner URL is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  status: Yup.string().required("Status is required"),
});

export const ModalForm = ({
  submitHandler,
  actionsLoading,
  onHide,
  preFill,
}) => {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(preFill?.image || null);
  const [fileName, setFileName] = useState(
    preFill?.image?.name || "No file chosen"
  );

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("image", file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFileName(file.name); // Update the file name
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
      setFileName("No file chosen"); // Reset the file name if no file is chosen
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        image: preFill ? preFill.image : null,
        bannerName: preFill ? preFill.bannerName : "",
        bannerURL: preFill ? preFill.bannerURL : "",
        startDate: preFill ? preFill.startDate : "",
        endDate: preFill ? preFill.endDate : "",
        status: preFill ? preFill.status : "active", // Assuming default value for status
      }}
      validationSchema={bannerSchema}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          {(() => console.log(values))()}
          <Modal.Body
            className="overlay overlay-block cursor-default"
            style={{ padding: "15px 26px" }}
          >
            <Form className="form form-label-right">
              <div className="form-group" style={{ minHeight: "120px" }}>
                <div className="d-flex align-items-center">
                  <div className="mr-3 d-flex align-items-center ">
                    <input
                      id="image"
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={(event) =>
                        handleImageChange(event, setFieldValue)
                      }
                      className="d-none"
                    />
                    <label
                      htmlFor="image"
                      className="btn btn-outline-primary mb-0 cursor-pointer p-2 px-4"
                    >
                      <i
                        className={`fas ${
                          imagePreview ? "fa-sync" : "fa-cloud-upload-alt"
                        } mr-2`}
                      />
                      {imagePreview ? "Change Image" : "Upload Image"}
                    </label>
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div
                    className="image-preview d-flex align-items-center"
                    style={{ maxWidth: "100px", paddingTop: "10px" }}
                  >
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{
                          width: "150px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "150px",
                          height: "100px",
                          backgroundColor: "#f8f9fa",
                          border: "1px solid #dee2e6",
                          borderRadius: "4px",
                        }}
                      ></div>
                    )}
                    <span className="ml-3 text-muted">{fileName}</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="bannerName">Banner Name</label>
                <Field type="text" name="bannerName" className="form-control" />
                <ErrorMessage
                  name="bannerName"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="bannerURL">Banner URL</label>
                <Field type="text" name="bannerURL" className="form-control" />
                <ErrorMessage
                  name="bannerURL"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <div className="row g-3">
                  <div className="col-6">
                    <label htmlFor="startDate" className="form-label">
                      Start Date
                    </label>
                    <Field
                      type="date"
                      name="startDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="endDate" className="form-label">
                      End Date
                    </label>
                    <Field
                      type="date"
                      name="endDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group d-flex align-items-center">
                <label className="mr-2">Status</label>
                <div className="d-flex align-items-center">
                  <Switch
                    checked={values.status === "active"}
                    onChange={(event) => {
                      setFieldValue(
                        "status",
                        event.target.checked ? "active" : "inactive"
                      );
                    }}
                    color="primary"
                    name="status-switch"
                    inputProps={{ "aria-label": "status switch" }}
                  />
                  <span className="ml-2">
                    {values.status === "active" ? "Active" : "Inactive"}
                  </span>
                </div>
                <ErrorMessage
                  name="status"
                  component="div"
                  className="text-danger ml-2"
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={actionsLoading}
            >
              {preFill ? "Save" : "Create"}
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
